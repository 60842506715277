import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Spinner } from '@class101/ui';
import { Playground, Props } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "spinner"
    }}>{`Spinner`}</h1>
    <p>{`진행 중인 사항이 있음을 알리기에 유용한 컴포넌트입니다.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Spinner} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Spinner />'} __scope={{
      props,
      DefaultLayout,
      Spinner,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Spinner mdxType="Spinner" />
    </Playground>
    <h2 {...{
      "id": "custom-example"
    }}>{`Custom example`}</h2>
    <Playground __position={2} __code={'<Spinner size={24} backgroundColor=\"#fc515a\" />'} __scope={{
      props,
      DefaultLayout,
      Spinner,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Spinner size={24} backgroundColor="#fc515a" mdxType="Spinner" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      